import { Rating as KendoRating } from '@progress/kendo-react-inputs';
import { logError } from 'utils/logger';
import { SquareItem } from './rating-square-item.component';
import { StarItem } from './rating-star-item.component';
import { RatingWrapper } from './rating.styles';

export const RatingTypes = {
    SQUARE: 'square',
    STAR: 'star',
};

export const Rating = (props) => {
    let { max, min } = props;
    const { ratingType } = props;
    let customItem = null;
    switch (ratingType) {
        case RatingTypes.SQUARE:
            customItem = SquareItem;
            break;
        case RatingTypes.STAR:
            customItem = StarItem;
            break;
        default:
            customItem = null;
    }
    if (!min && min !== 0) {
        min = 1;
    }

    if (!max && max !== 0) {
        max = 5;
    }

    if (min < 0) {
        logError('Zero value does not work'); // kendo error
    }

    const onChange = (e) => {
        e.correctValue = e.value - 1; // component bug hack
        if (e.correctValue < 0) {
            e.correctValue = 0;
        }
        if (props.onChange) {
            props.onChange(e);
        }
    };

    return <RatingWrapper data-test-id={props['data-test-id']}>
        <KendoRating
            {...props}
            item={customItem}
            onChange={onChange}
            min={min + 1}
            max={max + 1}
            value={props.value + 1} />
    </RatingWrapper>;
};
