import { colorNames } from 'app/app.styles';
import styled, { css } from 'styled-components';

export const sharedCheckboxStyles = css`
    .k-checkbox:checked,
    .k-checkbox.k-checked {
        border-color: var(${colorNames.ZenCyan});
        background-color: var(${colorNames.ZenCyan});
    }

    .k-checkbox:checked:focus, 
    .k-checkbox.k-checked.k-focus {
        box-shadow: 0 0 0 2px rgb(var(${colorNames.ZenCyan})/ 30%);
    }

    .k-checkbox {
        border-color: black;
    }
`;

export const CheckboxWrapper = styled.div`
    ${sharedCheckboxStyles}
    
    span {
        display: inline-flex;
        align-items: center;
        line-height: 16px;
    }

    label {
        line-height: 16px;
    }
`;

export const CheckboxDescription = styled.div`
    margin-left: 20px;
`;
