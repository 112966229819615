import { RatingItem } from '@progress/kendo-react-inputs';

export const SquareItem = (props) => {
    const icon = props.selected || props.hovered ? 'fa-solid fa-square' : 'fa-regular fa-square';
    const title = (props.value - 1).toString();
    return <RatingItem
    {...props}
    title={title}>
        <span>
            <i className={icon}></i>
        </span>
    </RatingItem>;
};
