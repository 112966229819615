import { displaySnippet } from 'utils/snippets';
import { stringInject } from 'utils/string';

export const currentLanguageSelector = (state) => state.translations.lang;
export const commonTextErrorSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.MessageError)
    || displaySnippet([values.length - 1]?.MessageError);
};

export const commonTextSuccessSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.MessageSuccess)
    || displaySnippet(values[values.length - 1]?.MessageSuccess);
};

export const commonTextWarningSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.MessageInfo)
    || displaySnippet(values[values.length - 1]?.MessageInfo);
};

export const commonTextSpinnerSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['Loader.PleaseWait.Small'])
    || displaySnippet(values[values.length - 1]?.['Loader.PleaseWait.Small'])
    || 'please wait';
};

export const commonTextYes = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.Yes)
    || displaySnippet(values[values.length - 1]?.Yes);
};

export const commonTextNewSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.New'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.New']);
};

export const commonTextRefreshSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.Refresh'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.Refresh']);
};

export const commonTextAddManySelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.AddMany'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.AddMany']);
};

export const commonTextNo = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.No)
    || displaySnippet(values[values.length - 1]?.No);
};

export const commonTextLogoutTitleSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['Logout.Title'])
    || displaySnippet(values[values.length - 1]?.['Logout.Title']);
};

export const commonTextLogoutQuestionSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['Logout.Question'])
    || displaySnippet(values[values.length - 1]?.['Logout.Question']);
};

export const commonTextAddSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.Add'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.Add']);
};

export const commonTextEditSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.Edit'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.Edit']);
};

export const commonTextDeleteSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.Delete'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.Delete']);
};

export const commonTextSaveSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.Save'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.Save']);
};

export const commonTextTitleLoadingSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.Title.Loading'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.Title.Loading']);
};

export const commonTextCancelSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.Cancel'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.Cancel']);
};

export const commonTextOkSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.Ok'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.Ok']);
};

export const commonTextLoadingSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.Loading'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.Loading']);
};

export const commonTextResetSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.Reset'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.Reset']);
};

export const commonTextShowMoreSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.ShowMore'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.ShowMore']);
};

export const commonTextShowLessSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.ShowLess'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.ShowLess']);
};

export const commonTextGridNoRecordsSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Grid.NoRecords'])
    || displaySnippet(values[values.length - 1]?.['MOT.Grid.NoRecords']);
};

export const commonTextGridGlobalStateSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    const data = {
        button: displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Grid.SaveLayout.Button'])
            || displaySnippet(values[values.length - 1]?.['MOT.Grid.SaveLayout.Button']),
        tooltip: displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Grid.SaveLayout.Tooltip'])
            || displaySnippet(values[values.length - 1]?.['MOT.Grid.SaveLayout.Tooltip']),
        title: displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Grid.SaveLayout.Title'])
            || displaySnippet(values[values.length - 1]?.['MOT.Grid.SaveLayout.Title']),
        content: displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Grid.SaveLayout.Content'])
            || displaySnippet(values[values.length - 1]?.['MOT.Grid.SaveLayout.Content']),
    };

    return data;
};

export const commonVirtualizationLoadingSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Virtualization.Loading'])
    || displaySnippet(values[values.length - 1]?.['MOT.Virtualization.Loading']);
};

export const commonPlaceholderNLettersSelector = (lang, n) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    const snippet = displaySnippet(state.translations.commonTranslations[lang]?.['SearchFilter.PleaseTypeIn'])
    || displaySnippet(values[values.length - 1]?.['SearchFilter.PleaseTypeIn']);

    return stringInject(snippet, [n]);
};

export const commonPleaseSelectSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    const snippet = displaySnippet(state.translations.commonTranslations[lang]?.PleaseSelect)
    || displaySnippet(values[values.length - 1]?.PleaseSelect);
    return snippet;
};

export const commonTextMinDateValidation = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.DateMinValidation)
    || displaySnippet(values[values.length - 1]?.DateMinValidation);
};

export const commonTextMaxDateValidation = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.DateMaxValidation)
    || displaySnippet(values[values.length - 1]?.DateMaxValidation);
};

export const commonTextClose = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.Close)
    || displaySnippet(values[values.length - 1]?.Close);
};

export const commonTextDatePickerYear = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.DatePicker.YYYY'])
    || displaySnippet(values[values.length - 1]?.['MOT.DatePicker.YYYY']);
};

export const commonTextDatePickerMonth = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.DatePicker.MM'])
    || displaySnippet(values[values.length - 1]?.['MOT.DatePicker.MM']);
};

export const commonTextDatePickerDay = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.DatePicker.DD'])
    || displaySnippet(values[values.length - 1]?.['MOT.DatePicker.DD']);
};

export const commonTextFieldRequiredSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.FieldRequired'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.FieldRequired']);
};

export const commonTextInvalidFormatSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.RegexPattern.InvalidFormat'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.RegexPattern.InvalidFormat']);
};

export const commonTextSendSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Common.Send'])
    || displaySnippet(values[values.length - 1]?.['MOT.Common.Send']);
};

export const commonTextUserFeedbackDialogTitleSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Menu.UserFeedback.Dialog.Title'])
    || displaySnippet(values[values.length - 1]?.['MOT.Menu.UserFeedback.Dialog.Title']);
};

export const commonTextUserFeedbackDialogHeaderSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Menu.UserFeedback.Dialog.Header'])
    || displaySnippet(values[values.length - 1]?.['MOT.Menu.UserFeedback.Dialog.Header']);
};

export const commonTextUserFeedbackDialogSpeedSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Menu.UserFeedback.Dialog.Speed'])
    || displaySnippet(values[values.length - 1]?.['MOT.Menu.UserFeedback.Dialog.Speed']);
};

export const commonTextUserFeedbackDialogFunctionalitySelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Menu.UserFeedback.Dialog.Functionality'])
    || displaySnippet(values[values.length - 1]?.['MOT.Menu.UserFeedback.Dialog.Functionality']);
};

export const commonTextUserFeedbackDialogWorkPlaceSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Menu.UserFeedback.Dialog.WorkPlace'])
    || displaySnippet(values[values.length - 1]?.['MOT.Menu.UserFeedback.Dialog.WorkPlace']);
};

export const commonTextUserFeedbackDialogCommentSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Menu.UserFeedback.Dialog.Comment'])
    || displaySnippet(values[values.length - 1]?.['MOT.Menu.UserFeedback.Dialog.Comment']);
};

export const commonTextUserFeedbackDialogUrgentSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['MOT.Menu.UserFeedback.Dialog.Urgent'])
    || displaySnippet(values[values.length - 1]?.['MOT.Menu.UserFeedback.Dialog.Urgent']);
};

export const commonTextUserFeedbackDialogWorkPlaceOfficeSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['McatMenu.UserFeedback.Office'])
    || displaySnippet(values[values.length - 1]?.['McatMenu.UserFeedback.Office']);
};

export const commonTextUserFeedbackDialogWorkPlaceHomeSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['McatMenu.UserFeedback.Home'])
    || displaySnippet(values[values.length - 1]?.['McatMenu.UserFeedback.Home']);
};

export const commonTextUserFeedbackDialogWorkPlaceOtherSelector = (lang) => (state) => {
    const values = Object.values(state.translations.commonTranslations);
    return displaySnippet(state.translations.commonTranslations[lang]?.['McatMenu.UserFeedback.Other'])
    || displaySnippet(values[values.length - 1]?.['McatMenu.UserFeedback.Other']);
};
