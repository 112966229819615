import { TextArea as KendoTextArea } from '@progress/kendo-react-inputs';
import React, { useCallback, useRef, useState } from 'react';
import { TextAreaWrapper } from './text-area.styles';

export const TextArea = React.forwardRef((props, ref) => {
    const value = props.value === null ? '' : props.value;
    const lastChangedOnBlur = useRef(value);

    const [state, setState] = useState(value);
    const setValue = useCallback((e) => {
        if (e.detail === null || e.detail === '') {
            setState('');
        } else {
            setState(e.detail || props.value);
        }
    }, [props.value]);

    const onRefChange = (node) => {
        if (node !== null && ref) {
            // eslint-disable-next-line no-param-reassign
            ref.current = node;
            const current = ref?.current;
            if (current) {
                current.setValue = (v) => {
                    setValue({ detail: v });
                };
            }
        }
    };

    return <TextAreaWrapper fontSize={props.fontSize}>
        <KendoTextArea
        {...props}
        value={state}
        ref={onRefChange}
        onChange={(e) => {
                setState(e.target.value);
                if (props.onChange) {
                    props.onChange(e);
                }
        }}
        onBlur={(e) => {
            if (props.onBlur && lastChangedOnBlur.current !== state) {
                props.onBlur(e, ref);
                lastChangedOnBlur.current = state;
            }
        }}
    />
    </TextAreaWrapper>;
});
