import { colorNames } from 'app/app.styles';
import styled from 'styled-components';

const H3Wrapper = styled.h3`
    font-size: ${(prop) => (prop.slim ? '0.9em;' : '1em;')}
    font-weight: normal;
    padding: 0;
    margin: 0;
    word-wrap: break-word;
    word-break: break-word;
    line-height: ${(props) => (props.multiLine ? '1.6em' : '2em !important')};
    font-family: Arial,Verdana,sans-serif;
    ${(props) => (props.slimLight || props.slim ? `color: var(${colorNames.ZenDarkGrey});` : `color: var(${colorNames.ZenCyan});`)}
    ${(props) => (props.slim ? 'text-transform: uppercase;' : '')}
`;

export const H3 = (props) => {
    const { slim, slimLight } = props;
    return <H3Wrapper {...props} slim={slim} slimLight={slimLight}>
        {props.children}
    </H3Wrapper>;
};
