import { colorNames } from 'app/app.styles';
import styled from 'styled-components';

export const RatingWrapper = styled.div`
    .k-rating-item:hover, 
    .k-rating-item.k-hover,
    .k-rating-item.k-selected {
        color: var(${colorNames.ZenCyan});
    }
    white-space: nowrap;
`;
