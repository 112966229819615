import { Checkbox as KendoCheckbox } from '@progress/kendo-react-inputs';
import { CheckboxWrapper } from './checkbox.styles';

export const marginLeft = '20px';

export const Checkbox = (props) => {
    const propsForCheckbox = { ...props };

    if (propsForCheckbox.wrapperClassName) {
        delete propsForCheckbox.wrapperClassName;
    }

    return <CheckboxWrapper className={`${props.wrapperClassName} ${propsForCheckbox.disabled ? 'text-disabled' : ''}`}>
            <KendoCheckbox {...propsForCheckbox} />
        </CheckboxWrapper>;
};
