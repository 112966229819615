import { RatingItem } from '@progress/kendo-react-inputs';

export const StarItem = (props) => {
    const icon = props.selected || props.hovered ? 'fa-solid fa-star' : 'fa-regular fa-star';
    const title = (props.value - 1).toString();
    return <RatingItem
    {...props}
    title={title}>
        <span>
            <i className={icon}></i>
        </span>
    </RatingItem>;
};
