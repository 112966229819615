import styled from 'styled-components';

export const FeedbackButtonWrapper = styled.div`
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
    line-height: 1;
    outline: 0;
    display: inline-block;
`;
