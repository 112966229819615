import { useState } from 'react';

export const useButton = (buttonConfig) => {
    const [enabled, setEnabled] = useState(buttonConfig.enabled !== undefined ? buttonConfig.enabled : true);
    const button = {
        ...buttonConfig,
        enabled: buttonConfig.enabled || enabled,
    };

    return [button, setEnabled];
};
