import { ValidationInputWrapper } from './validation-input.styles';

export const ValidationInput = (props) => {
    const { name, validation, className } = props;
    return <div className='d-flex flex-column'>
            {props.children != null ? <div>{props.children}</div> : null }
            <div id={`validation-${name}`}>
            {
                validation && validation[name]
                && validation[name].map((error, index) => <ValidationInputWrapper
                    className={`my-1 ${className || ''}`} key={index}>{error}</ValidationInputWrapper>)
            }
            </div>

    </div>;
};
