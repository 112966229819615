import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useMOTMutation } from 'hooks/useMOTMutation';
import { toggleSpinner } from 'store/spinner/spinner.slice';
import MOTApi, { formIsValid, NotifyTypes } from 'utils/motApi';
import { sendFeedbackUrl } from './urls';

export const feedbackDialogQueryKey = () => ['feedback-dialog'];

export const useChangeFeedbackDialog = (queryKey) => {
    const queryClient = useQueryClient();

    const change = useMOTMutation(({ field, value }) => ({ field, value }), {
        onSuccess: (d) => {
            queryClient.setQueryData(queryKey, (prev) => {
                const newState = {
                    ...prev,
                    [d.field]: d.value,
                };
                return newState;
            });
        },
    });

    return change;
};

export const useSendFeedback = (queryKey, closeDialog) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const change = useMOTMutation(async (data) => {
        toggleSpinner(true, dispatch);

        const pageName = document.title?.split(' |')[0];
        const dto = {
            speed: data.speed,
            functionality: data.functionality,
            workPlace: data.workPlace?.value,
            comment: data.comment,
            isUrgent: data.isUrgent,
            pageName,
            postedFrom: window.location.href,
        };

        const response = await MOTApi.post(sendFeedbackUrl(), dto);
        return response;
    }, {
        onSuccess: (response) => {
            if (response && formIsValid(response, dispatch, NotifyTypes.LARGE)) {
                closeDialog();
            } else {
                const errors = response?.data?.formErrors || {};

                queryClient.setQueryData(queryKey, (prev) => {
                    const newState = {
                        ...prev,
                        errors,
                    };

                    return newState;
                });
            }

            toggleSpinner(false, dispatch);
        },
    });

    return change;
};
