import { colorNames } from 'app/app.styles';
import styled from 'styled-components';

export const TextAreaWrapper = styled.div`
    .k-input.k-textarea {
        font-size: ${(props) => (props.fontSize ? props.fontSize : 'inherit')};
        border-color: var(${colorNames.ZenGrey});
        border-radius: 0px;
    }
`;
