import { useState } from 'react';
import { useSelector } from 'react-redux';
import { displaySnippet } from 'utils/snippets';
import { stringInject } from 'utils/string';
import { Dialog } from 'components/dialog/dialog.component';
import { useButton } from 'hooks/useButton';
import { nonExpiringQueryConfig, useMOTQuery } from 'hooks/useMOTQuery';
import {
    commonTextSendSelector,
    commonTextCancelSelector,
    commonTextUserFeedbackDialogTitleSelector,
    commonTextUserFeedbackDialogHeaderSelector,
    commonTextUserFeedbackDialogSpeedSelector,
    commonTextUserFeedbackDialogFunctionalitySelector,
    commonTextUserFeedbackDialogWorkPlaceSelector,
    commonTextUserFeedbackDialogCommentSelector,
    commonTextUserFeedbackDialogUrgentSelector,
    commonTextUserFeedbackDialogWorkPlaceOfficeSelector,
    commonTextUserFeedbackDialogWorkPlaceHomeSelector,
    commonTextUserFeedbackDialogWorkPlaceOtherSelector,
    currentLanguageSelector,
} from 'store/translations/translations.selectors';
import { Row, Col } from 'react-bootstrap';
import { H3 } from 'components/headers/h3.component';
import { Spinner } from 'components/spinner/spinner.component';
import { Checkbox } from 'components/kendo/checkbox/checkbox.component';
import { DropDownList } from 'components/kendo/drop-down-list/drop-down-list.component';
import { Rating, RatingTypes } from 'components/kendo/rating/rating.component';
import { TextArea } from 'components/kendo/text-area/text-area.component';
import { ValidationInput } from 'components/validation-input/validation-input.component';
import { WorkPlaceType, SpeedRatingEnum, FunctionalityRatingEnum } from 'utils/mcat-enums/system.enums';
import { HorizontalMenuIcon } from '../horizontal-menu-icon.component';
import { FeedbackButtonWrapper } from './feedback-button-style';
import { feedbackDialogQueryKey, useChangeFeedbackDialog, useSendFeedback } from './feedback-button-queries';

export const FeedbackButton = ({ textColor }) => {
    const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);

    const lang = useSelector(currentLanguageSelector);
    const sendSnippet = useSelector(commonTextSendSelector(lang));
    const cancelSnippet = useSelector(commonTextCancelSelector(lang));

    const dialogTitleSnippet = useSelector(commonTextUserFeedbackDialogTitleSelector(lang));
    const dialogHeaderSnippet = useSelector(commonTextUserFeedbackDialogHeaderSelector(lang));
    const dialogSpeedSnippet = useSelector(commonTextUserFeedbackDialogSpeedSelector(lang));
    const dialogFunctionalitySnippet = useSelector(commonTextUserFeedbackDialogFunctionalitySelector(lang));
    const dialogWorkPlaceSnippet = useSelector(commonTextUserFeedbackDialogWorkPlaceSelector(lang));
    const dialogCommentSnippet = useSelector(commonTextUserFeedbackDialogCommentSelector(lang));
    const dialogUrgentSnippet = useSelector(commonTextUserFeedbackDialogUrgentSelector(lang));

    const dialogWorkPlaceOfficeSnippet = useSelector(commonTextUserFeedbackDialogWorkPlaceOfficeSelector(lang));
    const dialogWorkPlaceHomeSnippet = useSelector(commonTextUserFeedbackDialogWorkPlaceHomeSelector(lang));
    const dialogWorkPlaceOtherSnippet = useSelector(commonTextUserFeedbackDialogWorkPlaceOtherSelector(lang));

    const pageName = document.title?.split(' |')[0];

    const queryKey = feedbackDialogQueryKey();
    const { data, isLoading } = useMOTQuery({
        queryKey,
        initialData: {
            speed: SpeedRatingEnum.Normal,
            functionality: FunctionalityRatingEnum.Normal,
            workPlace: { value: WorkPlaceType.Office, text: dialogWorkPlaceOfficeSnippet },
            comment: '',
            isUrgent: false,
        },
        ...nonExpiringQueryConfig,
        cacheTime: 0,
    });

    const closeDialog = () => {
        setShowFeedbackDialog(false);
    };

    const changeFeedbackDialog = useChangeFeedbackDialog(queryKey);
    const sendFeedback = useSendFeedback(queryKey, closeDialog);

    const [sendButton] = useButton({
        buttonId: 'user-feedback-dialog-send',
        primary: true,
        text: displaySnippet(sendSnippet),
        title: displaySnippet(sendSnippet),
        onClick: () => sendFeedback.mutate(data),
    });

    const [cancelButton] = useButton({
        buttonId: 'user-feedback-dialog-cancel',
        primary: false,
        text: displaySnippet(cancelSnippet),
        title: displaySnippet(cancelSnippet),
        onClick: closeDialog,
    });

    const workPlaceTypes = [
        {
            value: WorkPlaceType.Office,
            text: dialogWorkPlaceOfficeSnippet,
        },
        {
            value: WorkPlaceType.Home,
            text: dialogWorkPlaceHomeSnippet,
        },
        {
            value: WorkPlaceType.Other,
            text: dialogWorkPlaceOtherSnippet,
        },
    ];

    if (isLoading) {
        return <Spinner showAlways={true}/>;
    }

    return <>
        <FeedbackButtonWrapper
            onClick={() => setShowFeedbackDialog(true)}
            >
            <HorizontalMenuIcon textColor={textColor} icon='fa-regular fa-comments' />
        </FeedbackButtonWrapper>
        { showFeedbackDialog && <Dialog
            data-test-id='user-feedback-dialog'
            title={displaySnippet(dialogTitleSnippet)}
            width={500}
            onClose={closeDialog}
            buttons={[
                sendButton,
                cancelButton,
            ]}
        >
            <p dangerouslySetInnerHTML={
                { __html: displaySnippet(stringInject(dialogHeaderSnippet, [pageName, '<i class="fa fa-comments-o"></i>'])) }
            }></p>
            <div className='mb-3'>
                <Row>
                    <Col md={6} sm={12}>
                        <H3 slim={true}>{displaySnippet(dialogSpeedSnippet)}</H3>
                        <Rating
                            data-test-id='user-feedback-dialog-speed'
                            value={data.speed}
                            min={1}
                            max={5}
                            ratingType={RatingTypes.STAR}
                            onChange={(e) => {
                                changeFeedbackDialog.mutate({
                                    field: 'speed',
                                    value: e.correctValue,
                                });
                            }}
                            className='mb-2'
                        />
                        <H3 slim={true}>{displaySnippet(dialogFunctionalitySnippet)}</H3>
                        <Rating
                            data-test-id='user-feedback-dialog-functionality'
                            value={data.functionality}
                            min={1}
                            max={5}
                            ratingType={RatingTypes.STAR}
                            onChange={(e) => {
                                changeFeedbackDialog.mutate({
                                    field: 'functionality',
                                    value: e.correctValue,
                                });
                            }}
                        />
                    </Col>
                    <Col md={6} sm={12} className='justify-content-end'>
                        <H3 slim={true}>{displaySnippet(dialogWorkPlaceSnippet)}</H3>
                        <DropDownList
                            data-test-id='user-feedback-dialog-workplace'
                            data={workPlaceTypes}
                            value={data.workPlace}
                            textField='text'
                            dataItemKey='value'
                            onChange={(e) => {
                                changeFeedbackDialog.mutate({
                                    field: 'workPlace',
                                    value: e.target.value,
                                });
                            }}
                        />
                    </Col>
                </Row>
            </div>
            <div>
                <H3 slim={true}>{displaySnippet(dialogCommentSnippet)}</H3>
                <ValidationInput validation={data.errors} name={'Comment'}>
                    <TextArea
                        data-test-id='user-feedback-dialog-comment'
                        value={data.comment}
                        rows={8}
                        onChange={(e) => {
                            changeFeedbackDialog.mutate({
                                field: 'comment',
                                value: e.target.value,
                            });
                        }}
                    />
                </ValidationInput>
                <Checkbox
                    data-test-id='user-feedback-dialog-urgent'
                    value={data.isUrgent}
                    label={displaySnippet(dialogUrgentSnippet)}
                    className='mt-1'
                    onChange={(e) => {
                        changeFeedbackDialog.mutate({
                            field: 'isUrgent',
                            value: e.target.value,
                        });
                    }}
                />
            </div>
        </Dialog>}
    </>;
};
